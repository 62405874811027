import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class ProveedorService {

  async actualizoPagoProveedor(pagoProveedor) {
    const pagoProveedorActualizado = await fetchWrapper.put(
      `${ruta}/pago_proveedor/` + pagoProveedor.id,
      pagoProveedor
    );
    return pagoProveedorActualizado;
  }

  async getDetallePagos(id) {
    const detallePagos = await fetchWrapper.get(
      `${ruta}/pago_proveedor/detalle_pagos/` + id
    );
    return detallePagos;
  }

  async imprimirDetalle(id) {
    return await fetchWrapper.getPdf(
      `${ruta}/pago_proveedor/imprimir_detalle/${id}`
    );
  }
}